import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, Theme, Button, useTheme } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { FloatNavbar } from '../../components/_external-pages/landing/components/FloatNavbar';
import { useGoogleTagManager } from '../../hooks/useGoogleTagManager';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: string;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export interface MainNavbarProps {
  title?: string;
}

interface FloatButtonProps {
  isOffset: boolean;
  theme: Theme;
}

const FloatButton = styled(Button)(({ theme, isOffset }: FloatButtonProps) => ({
  [theme.breakpoints.down('md')]: {
    margin: '1em auto',
    width: '50%'
  },
  [theme.breakpoints.down('sm')]: {
    margin: '1em auto',
    width: '80%'
  },
  [theme.breakpoints.down('xs')]: {
    margin: '1em auto',
    width: '90%'
  }
}));

export default function MainNavbar({ title }: MainNavbarProps) {
  const isOffset = true;
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  const theme = useTheme();

  const { event } = useGoogleTagManager();
  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'rgba(0, 0, 0, 0.48)' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/" aria-label="Logotipo Iban Online">
            <Logo isOffset={isOffset} />
          </RouterLink>
          {title && (
            <Label color="info" sx={{ ml: 1 }}>
              {title}
            </Label>
          )}
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          <FloatNavbar
            buttons={[
              () => (
                <FloatButton
                  theme={theme}
                  variant="contained"
                  href={
                    process.env.REACT_APP_APP_IBAN_ONLINE ||
                    'http://dev.app.ibanonline.com.mx/online/register'
                  }
                  onClick={() => event({ path: 'Register', event: 'click' })}
                  isOffset={isOffset}
                >
                  Solicitar crédito
                </FloatButton>
              )
            ]}
            isOffset={isOffset}
          />

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
