import { Outlet } from 'react-router-dom';
import MainNavbar from './MainNavbar';
import { Poncho } from '../../components/Poncho';

// ----------------------------------------------------------------------

export default function AgentesLayout() {
  return (
    <>
      <MainNavbar title={'Agentes'} />
      <div>
        <Outlet />
      </div>
      <Poncho />
    </>
  );
}
