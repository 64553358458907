import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Nosotros',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/nosotros'
  },
  {
    title: 'Productos',
    path: '/productos',
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Productos',
        items: [
          { title: 'Crédito Solución', path: '/productos/credito-solucion' },
          { title: 'Crédito Comercial', path: '/productos/credito-comercial' },
          { title: 'Crédito Construcción', path: '/productos/credito-construccion' },
          { title: 'Crédito Mejora Tu Cuota', path: '/productos/mejora-tu-cuota' }
        ]
      }
    ]
  },
  {
    title: 'Agentes',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/agentes'
  },
  {
    title: 'Refiere y gana',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/refiere-y-gana'
  }
];

export default menuConfig;
