import TagManager from 'react-gtm-module';

interface GoogleTagManagerProps {
  event: string;
  path: string;
}

TagManager.initialize({
  gtmId: 'GTM-P2VR59C'
});

/*Eventos: Registro Cliente, Simulador de Crédito, Contacto WA, Registro Agente, Inicio de Sesión Agentes,
 * */

export const useGoogleTagManager = () => {
  const visit = (path: string) => event({ path, event: 'visit' });

  const event = ({ path, event }: GoogleTagManagerProps) => {
    console.log(path, event);
    TagManager.dataLayer({
      dataLayer: {
        event,
        path
      }
    });
  };
  const push = (object: any) => {
    TagManager.dataLayer(object);
  };
  return {
    visit,
    event,
    push
  };
};
