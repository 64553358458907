// ----------------------------------------------------------------------

const isMac = window.navigator.platform.match(/^Mac/) || window.navigator.platform.match(/^iPhone/);

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ fs }: { fs: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(fs)
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(fs)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(fs)
    }
  };
}
const typography = {
  h1: {
    fontFamily: isMac ? 'Montserrat-Bold' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    weight: 700,
    fontSize: pxToRem(64),
    lineHeight: '80px',
    ...responsiveFontSizes({ fs: 64 })
  },
  h2: {
    fontFamily: isMac ? 'Montserrat-Bold' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    weight: 700,
    fontSize: pxToRem(48),
    lineHeight: '64px',
    ...responsiveFontSizes({ fs: 48 })
  },
  h3: {
    fontFamily: isMac ? 'Montserrat-Bold' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    weight: 700,
    fontSize: pxToRem(32),
    lineHeight: '48px',
    ...responsiveFontSizes({ fs: 32 })
  },
  h4: {
    fontFamily: isMac ? 'Montserrat-Bold' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    weight: 700,
    fontSize: pxToRem(24),
    lineHeight: '36px',
    ...responsiveFontSizes({ fs: 24 })
  },
  h5: {
    fontFamily: isMac ? 'Montserrat-Bold' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    weight: 700,
    fontSize: pxToRem(20),
    lineHeight: '30px',
    ...responsiveFontSizes({ fs: 20 })
  },
  h6: {
    fontFamily: isMac ? 'Montserrat-Bold' : 'Gotham',
    fontStyle: 'normal',
    weight: 700,
    fontWeight: 'bold',
    fontSize: pxToRem(18),
    lineHeight: '28px',
    ...responsiveFontSizes({ fs: 18 })
  },
  subtitle1: {
    fontFamily: isMac ? 'Montserrat' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    weight: 400,
    fontSize: pxToRem(16),
    lineHeight: '24px',
    ...responsiveFontSizes({ fs: 16 })
  },
  subtitle2: {
    fontFamily: isMac ? 'Montserrat' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'normal',
    weight: 400,
    fontSize: pxToRem(14),
    lineHeight: '22px',
    ...responsiveFontSizes({ fs: 14 })
  },
  body1: {
    fontFamily: isMac ? 'Montserrat' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'normal',
    weight: 400,
    fontSize: pxToRem(16),
    lineHeight: '24px',
    ...responsiveFontSizes({ fs: 16 })
  },
  body2: {
    fontFamily: isMac ? 'Montserrat' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'normal',
    weight: 400,
    fontSize: pxToRem(14),
    lineHeight: '22px',
    ...responsiveFontSizes({ fs: 14 })
  },
  caption: {
    fontFamily: isMac ? 'Montserrat-Bold' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    weight: 700,
    fontSize: pxToRem(12),
    lineHeight: '28px',
    ...responsiveFontSizes({ fs: 12 })
  },
  overline: {
    fontFamily: isMac ? 'Montserrat-Bold' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    weight: 700,
    fontSize: pxToRem(12),
    lineHeight: '18px',
    ...responsiveFontSizes({ fs: 12 }),
    letterSpacing: '1.1px',
    textTransform: 'uppercase'
  },
  button: {
    fontFamily: isMac ? 'Montserrat-Bold' : 'Gotham',
    fontStyle: 'normal',
    fontWeight: 'bold',
    weight: 400,
    fontSize: pxToRem(15),
    lineHeight: '26px',
    ...responsiveFontSizes({ fs: 15 })
  }
} as const;

export default typography;
