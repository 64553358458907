import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Container,
  useTheme,
  Typography,
  Stack
} from '@mui/material';
// hooks
// components
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { FloatNavbar } from '../../components/_external-pages/landing/components/FloatNavbar';
import { useGoogleTagManager } from '../../hooks/useGoogleTagManager';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: string;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export interface MainNavbarProps {
  title?: string;
}

const FloatButton = styled(Button)(({ theme }) => ({
  margin: 'auto 1em',
  [theme.breakpoints.down('md')]: {
    margin: '1em auto',
    width: '50%'
  }
}));
export default function MainNavbar({ title }: MainNavbarProps) {
  const isOffset = true;
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const theme = useTheme();

  const { push } = useGoogleTagManager();

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'rgba(0, 0, 0, 0.48)' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/" aria-label="Logotipo Iban Online">
            <Logo isOffset={isOffset} />
          </RouterLink>
          {title && (
            <Stack direction={'row'} spacing={2}>
              <span />
              <span
                style={{
                  borderRight: '1px solid black',
                  height: '2em'
                }}
              />
              <Typography
                variant={'overline'}
                style={{
                  marginTop: '.5em',
                  color: isOffset ? theme.palette.common.black : theme.palette.common.white
                }}
              >
                {title}
              </Typography>
            </Stack>
          )}
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          <FloatNavbar
            isOffset={isOffset}
            buttons={[
              () => (
                <FloatButton
                  color={'primary'}
                  size={'medium'}
                  variant={'outlined'}
                  sx={{
                    display: {
                      sm: 'none',
                      xs: 'none',
                      md: 'inherit',
                      lg: 'inherit',
                      xl: 'inherit'
                    }
                  }}
                  onClick={() =>
                    push({
                      name: 'signup_button_click',
                      event: 'signup_button_click',
                      context: 'action'
                    })
                  }
                  href={
                    process.env.REACT_APP_APP_IDEX_ONLINE || 'https://dev-idexi.ibanonline.com/'
                  }
                >
                  Iniciar sesión
                </FloatButton>
              ),
              () => (
                <FloatButton
                  color={'primary'}
                  size={'medium'}
                  variant={'contained'}
                  href={'/agentes/register'}
                >
                  Únete ahora
                </FloatButton>
              )
            ]}
          />
          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
