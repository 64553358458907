// material
import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/system';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  isOffset?: boolean;
}

export default function Logo({ sx, isOffset = true }: LogoProps) {
  const { palette } = useTheme();

  if (!palette) return null;
  if (!palette.primary) return null;
  if (!palette.primary.dark) return null;

  return (
    <Box sx={{ width: 109, height: 58, ...sx }}>
      {isOffset ? (
        <svg
          width="109"
          height="58"
          viewBox="0 0 109 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M83.3043 0H83.3932L83.4069 0.0273059C84.3624 0.607556 85.3475 1.13775 86.3167 1.69979C93.8031 5.95724 101.303 10.1942 108.783 14.4607C108.263 14.8112 107.693 15.0842 107.155 15.4051C104.975 16.6316 102.813 17.8945 100.626 19.1073C99.6546 18.593 98.7128 18.0264 97.7527 17.4917C92.948 14.7679 88.1478 12.0419 83.3454 9.31359C77.5897 12.5948 71.8272 15.867 66.067 19.1414C63.367 17.5713 60.6032 16.1082 57.942 14.4676C65.9552 9.88474 73.9867 5.34058 82.0045 0.769116C82.4332 0.511986 82.8961 0.300365 83.3043 0Z"
            fill={palette.primary.light}
          />
          <path
            d="M3.7352 26.489C4.56526 26.2091 5.38847 25.9019 6.22081 25.622C5.5481 26.8576 4.88451 28.1 4.36231 29.4084C3.84238 30.7123 3.40911 32.0753 3.34754 33.4861C3.30193 34.5078 3.52997 35.5978 4.23005 36.3783C4.96432 37.1997 6.0589 37.5797 7.11927 37.7413C7.61867 37.83 8.13176 37.8141 8.63116 37.9028C8.56731 37.9097 8.44417 37.9256 8.38032 37.9347C7.56851 37.9711 6.74757 38.003 5.94032 37.8778C4.84118 37.7253 3.7352 37.2839 2.99408 36.4329C2.18455 35.5158 1.89038 34.2643 1.86986 33.0697C1.8425 31.1992 2.33506 29.3606 2.98724 27.6222C3.11494 27.3104 3.21756 26.9873 3.37719 26.6869C3.45472 26.5641 3.61435 26.5459 3.7352 26.489Z"
            fill={palette.primary.dark}
          />
          <path
            d="M83.3384 26.2114C86.6085 24.3546 89.8831 22.5137 93.1463 20.6478C93.1874 21.4829 93.1372 22.3203 93.1623 23.1577C93.1691 23.5104 93.1714 23.8631 93.16 24.2158C92.7655 24.4183 92.3938 24.664 92.0061 24.8825C91.4885 25.176 90.9686 25.465 90.4646 25.779C89.4909 26.3092 88.5286 26.8667 87.5617 27.4106C86.5013 28.0386 85.4159 28.6257 84.3532 29.2537C84.0248 29.4244 83.7238 29.6519 83.3726 29.7725C83.3749 29.3265 83.3202 28.8805 83.3225 28.4323C83.3567 27.6927 83.2905 26.9509 83.3384 26.2114Z"
            fill={palette.primary.dark}
          />
          <path
            d="M93.1144 27.3127L93.1486 27.2922C93.1646 31.0149 93.1235 34.7354 93.1486 38.4581C90.9572 39.7574 88.727 40.9952 86.5196 42.265C85.4524 42.8634 84.4034 43.5006 83.3202 44.0717L83.3248 44.0103C83.3453 43.694 83.3544 43.3777 83.3522 43.0637C83.3544 41.2979 83.3111 39.5344 83.3271 37.7686C83.3339 37.0018 83.3111 36.2349 83.3157 35.4681C83.3522 34.5988 83.3111 33.7319 83.3362 32.8626C86.5994 31.024 89.8672 29.1877 93.1144 27.3127Z"
            fill={palette.primary.dark}
          />
          <path
            d="M3.77177 17.8944C4.46956 17.7625 5.2198 17.9195 5.79902 18.3359C6.49453 18.8228 6.9278 19.6557 6.91412 20.5044C6.91868 21.5056 6.28702 22.4705 5.37031 22.8778C4.65199 23.21 3.78089 23.1941 3.07626 22.8345C2.44231 22.5182 1.94747 21.9334 1.74224 21.2576C1.5142 20.5295 1.62594 19.7035 2.04553 19.0618C2.42863 18.4588 3.06942 18.0264 3.77177 17.8944Z"
            fill={palette.primary.main}
          />
          <path
            d="M76.7412 18.8274C78.9509 17.5782 81.1606 16.3244 83.3748 15.0819C86.638 16.9296 89.8899 18.791 93.1462 20.6478C89.883 22.5137 86.6084 24.3546 83.3384 26.2114C82.864 25.9929 82.4262 25.7062 81.9724 25.4536C79.594 24.0906 77.2064 22.7412 74.828 21.376C74.4153 21.1347 73.9934 20.9072 73.592 20.6455C74.6228 20.0084 75.6923 19.435 76.7412 18.8274Z"
            fill={palette.primary.lighter}
          />
          <path
            d="M57.9328 14.5017L57.967 14.4789C60.6259 16.1241 63.3851 17.5918 66.0851 19.1665C66.0828 25.713 66.0714 32.2551 66.0623 38.7994C71.802 42.108 77.5782 45.3551 83.3156 48.6705C83.3019 49.6034 83.3452 50.5364 83.3087 51.4671C83.3087 53.1464 83.2905 54.8234 83.3224 56.5027C83.3224 56.9487 83.3635 57.3924 83.3361 57.8384C83.3452 57.868 83.3635 57.9295 83.3726 57.959L83.3635 58H83.3361C81.4092 56.8805 79.4709 55.7814 77.5371 54.6732C73.3276 52.2749 69.1203 49.8697 64.9107 47.4758C62.571 46.1583 60.2542 44.8021 57.9077 43.5006C57.8598 41.0817 57.894 38.6629 57.8894 36.244L57.9145 17.1413C57.9214 16.2584 57.9008 15.38 57.9328 14.5017Z"
            fill={palette.primary.main}
          />
          <path
            d="M1.71712 25.4559C3.67368 24.771 5.62567 24.0724 7.58223 23.3875C7.12388 24.1202 6.66552 24.8552 6.24137 25.6084C7.04863 25.3262 7.85588 25.0395 8.66085 24.7551C7.53206 26.5345 6.47625 28.3731 5.69865 30.3346C5.26766 31.4541 4.90508 32.6237 4.86175 33.832C4.81842 34.8059 5.07154 35.8481 5.79442 36.5421C6.65868 37.3704 7.8992 37.6321 9.05991 37.6958C9.43389 37.6935 9.79875 37.8073 10.1727 37.7959C10.0222 37.8119 9.86716 37.8119 9.71438 37.821C9.3518 37.7322 8.99378 37.8756 8.63348 37.9029C8.1318 37.8164 7.621 37.8301 7.1216 37.7413C6.05894 37.582 4.96437 37.1997 4.23009 36.3783C3.53001 35.5978 3.30198 34.5078 3.34758 33.4861C3.41143 32.0753 3.84242 30.7123 4.36235 29.4085C4.88455 28.1023 5.54814 26.8576 6.22085 25.622C5.38852 25.9019 4.5653 26.2114 3.73525 26.489C3.61439 26.5436 3.45476 26.5641 3.37723 26.6824C3.2176 26.9828 3.11727 27.3059 2.98729 27.6177C2.33738 29.3561 1.84254 31.1947 1.8699 33.0652C1.89043 34.2598 2.18688 35.5113 2.99413 36.4284C3.73525 37.2817 4.84123 37.7208 5.94037 37.8733C6.74762 37.9984 7.56855 37.9666 8.38036 37.9302C6.94601 38.1623 5.46149 38.2351 4.0317 37.937C2.91203 37.7004 1.8243 37.1497 1.11282 36.2372C0.30785 35.2178 0.0296448 33.8935 0 32.6237V32.2551C0.0775326 29.8977 0.786728 27.6063 1.71712 25.4559Z"
            fill={palette.primary.main}
          />
          <path
            d="M73.5625 20.6296L73.5899 20.6478C73.9912 20.9072 74.4131 21.1348 74.8258 21.3782C77.202 22.7435 79.5895 24.0929 81.9702 25.4536C82.424 25.7062 82.8619 25.9929 83.3362 26.2114C83.2883 26.9509 83.3544 27.6927 83.3225 28.4323C83.3179 28.8805 83.3749 29.3265 83.3727 29.7725C82.8322 29.438 82.3009 29.0853 81.7308 28.8009C80.5769 28.2434 79.5052 27.538 78.3832 26.9168C77.6717 26.5368 76.9808 26.1226 76.2693 25.7449C75.4233 25.2466 74.5636 24.7665 73.6948 24.3068C73.5671 24.2658 73.6013 24.1134 73.5853 24.011C73.5625 22.8823 73.5671 21.756 73.5625 20.6296Z"
            fill={palette.primary.main}
          />
          <path
            d="M73.5397 27.2809L73.5853 27.3036C74.7779 27.9681 75.9569 28.6553 77.1404 29.3311C79.2041 30.5098 81.2701 31.684 83.3338 32.8649C83.3088 33.7342 83.3475 34.6011 83.311 35.4704C83.3065 36.2372 83.3316 37.0041 83.3247 37.7709C83.3088 39.5367 83.3498 41.3002 83.3475 43.066C83.3498 43.3823 83.3407 43.6986 83.3202 44.0148C82.9804 43.8419 82.6794 43.6075 82.3442 43.4255C81.7148 43.0455 81.0375 42.7542 80.4059 42.3765C79.3911 41.7894 78.3832 41.1864 77.3502 40.6289C76.0937 39.9144 74.844 39.184 73.567 38.5059C73.5306 35.2883 73.5625 32.0731 73.5602 28.8578C73.567 28.3276 73.5556 27.8042 73.5397 27.2809Z"
            fill={palette.primary.main}
          />
          <path
            d="M107.177 15.5029C107.718 15.1821 108.288 14.9113 108.808 14.5609C108.865 15.0433 108.819 15.5325 108.833 16.0195C108.822 24.8984 108.81 33.7797 108.796 42.6609C108.769 42.9545 108.817 43.2708 108.744 43.5506C107.827 44.0968 106.885 44.6042 105.962 45.1367C99.399 48.8525 92.8407 52.5707 86.2801 56.2911C85.3132 56.8509 84.3235 57.3743 83.3703 57.9591C83.3612 57.9295 83.3452 57.868 83.3338 57.8385C83.3612 57.3925 83.3201 56.9487 83.3201 56.5027C83.2859 54.8234 83.3042 53.1464 83.3065 51.4671C83.3452 50.5341 83.2996 49.6012 83.3133 48.6705C88.2594 45.8603 93.2147 43.0705 98.1539 40.2489C98.5827 40.0191 99.0114 39.787 99.4195 39.5207C99.7821 39.2864 100.177 39.1089 100.557 38.9086C100.653 38.87 100.628 38.7539 100.644 38.6743C100.701 37.7823 100.658 36.888 100.628 35.996C100.626 30.396 100.653 24.7938 100.64 19.1938C102.831 17.9832 104.995 16.7249 107.177 15.5029Z"
            fill={palette.primary.dark}
          />
          <path
            d="M30.0804 23.6765C30.2309 23.6469 30.3974 23.7106 30.4818 23.8403C30.6414 24.0633 30.7372 24.3227 30.8489 24.573C32.3448 28.0568 33.8157 31.5497 35.3139 35.0312C35.697 35.8913 36.0253 36.8243 36.7231 37.4842C37.0903 37.8414 37.6307 37.8164 38.105 37.8551C38.2464 37.8596 38.3126 38.0326 38.2396 38.1463C38.1119 38.2442 37.934 38.2237 37.7812 38.2419C36.4449 38.2533 35.1064 38.2328 33.7678 38.1987C33.5671 38.185 33.3573 38.1987 33.168 38.1213C33.0312 37.9802 33.225 37.8505 33.3573 37.8323C33.5557 37.805 33.6241 37.5729 33.5397 37.4114C33.0426 36.1598 32.5523 34.906 32.0484 33.6591C32.0073 33.5021 31.8226 33.5385 31.7018 33.5316C30.2036 33.5407 28.7008 33.5112 27.2049 33.5385C26.9061 34.1324 26.6895 34.7672 26.4204 35.3771C26.181 35.9869 25.8572 36.5808 25.8093 37.2452C25.791 37.425 25.848 37.6321 26.0214 37.7208C26.3224 37.8869 26.6781 37.7868 27.0042 37.8437C27.2163 37.8619 27.1912 38.2169 26.9814 38.2169C26.2197 38.2533 25.4581 38.1532 24.6987 38.1577C23.8915 38.1759 23.082 38.2487 22.2724 38.2146C22.0603 38.2351 21.9988 37.9097 22.188 37.8414C22.5552 37.7823 22.9451 37.8483 23.2917 37.689C23.8413 37.4751 24.1378 36.9267 24.3635 36.417C26.1171 32.3666 27.8707 28.3139 29.6221 24.2636C29.7406 24.0474 29.8296 23.763 30.0804 23.6765ZM29.223 28.4004C28.6917 29.7634 28.1216 31.1128 27.6131 32.4849C28.9288 32.4872 30.2423 32.5168 31.5558 32.4735C30.9264 30.7942 30.2742 29.1217 29.6312 27.447C29.451 27.7428 29.3644 28.0841 29.223 28.4004Z"
            fill={palette.common.black}
          />
          <path
            d="M41.1903 24.4138C41.2017 24.193 41.2314 23.8858 41.4776 23.7994C41.749 23.7834 41.9428 24.0133 42.1275 24.1771C43.986 26.0544 45.8377 27.9362 47.7259 29.7816C48.7999 30.8261 49.8534 31.891 50.907 32.9582C51.4657 33.5021 51.9856 34.0891 52.5716 34.608C52.5374 31.7909 52.4508 28.9738 52.3938 26.1568C52.3618 25.6949 52.3869 25.1487 52.0312 24.7983C51.6162 24.4456 51.0347 24.5617 50.5376 24.4888C50.3414 24.4365 50.4007 24.1361 50.606 24.1566C51.737 24.0906 52.8704 24.2249 54.0014 24.1634C54.51 24.1612 55.0208 24.1111 55.5293 24.1634C55.6615 24.1612 55.7664 24.3136 55.6729 24.4251C55.5407 24.5526 55.3423 24.507 55.1781 24.5298C54.8338 24.5412 54.4256 24.6413 54.2568 24.9735C54.0881 25.3217 54.0881 25.7176 54.0721 26.0953C53.9969 29.8931 53.9193 33.6887 53.8441 37.4887C53.8076 37.8141 53.9307 38.3011 53.5704 38.4786C53.2672 38.5036 52.9912 38.3352 52.7814 38.1327C50.7542 36.2395 48.7543 34.319 46.759 32.3916C45.6644 31.3244 44.6017 30.2231 43.5482 29.1126C43.2016 28.7554 42.8755 28.3754 42.497 28.05C42.554 30.6623 42.6361 33.2745 42.709 35.8845C42.7546 36.4466 42.7432 37.0814 43.1058 37.5479C43.4844 37.8915 44.0476 37.8027 44.5174 37.8642C44.6565 37.8778 44.7477 38.0189 44.6816 38.1486C44.5767 38.2419 44.4284 38.2442 44.2985 38.251C43.4638 38.2601 42.6315 38.2078 41.7992 38.1805C41.0398 38.1987 40.2804 38.2647 39.5211 38.2419C39.4116 38.2283 39.2452 38.226 39.2178 38.0917C39.1767 37.9461 39.3318 37.8596 39.4549 37.8483C39.813 37.8187 40.1892 37.846 40.529 37.7094C40.7548 37.6071 40.8483 37.3545 40.8961 37.1292C40.9851 36.6468 40.9828 36.1553 40.9988 35.6661C41.0581 31.916 41.1265 28.166 41.1903 24.4138Z"
            fill={palette.common.black}
          />
          <path
            d="M9.21033 24.2363C9.28558 24.077 9.49081 24.0998 9.63904 24.0838C10.5375 24.0861 11.436 24.118 12.3344 24.1293C13.2808 24.1271 14.2271 24.0975 15.1735 24.0907C15.983 24.0952 16.8108 24.1771 17.5656 24.4843C18.1653 24.7255 18.7149 25.1419 19.0182 25.7222C19.4013 26.4253 19.3831 27.2946 19.0775 28.0227C18.7012 28.9284 17.951 29.6224 17.146 30.1548C18.2087 30.5121 19.2234 31.1128 19.9212 32.0025C20.4412 32.6579 20.7285 33.4975 20.6874 34.3349C20.6601 35.4818 20.0489 36.5831 19.1163 37.2476C18.0399 38.0303 16.6694 38.2784 15.3628 38.251C14.3001 38.2169 13.2397 38.1304 12.1748 38.1395C11.5067 38.1532 10.8362 38.1737 10.1681 38.201C9.97425 38.1987 9.76674 38.2237 9.58659 38.1418C9.42924 38.0372 9.53414 37.8096 9.71657 37.8142C9.86935 37.8073 10.0221 37.8051 10.1749 37.7891C10.4212 37.7573 10.7154 37.7209 10.8636 37.4956C11.0437 37.2066 11.062 36.8562 11.0985 36.5262C11.1828 35.3339 11.16 34.137 11.1669 32.94C11.1669 30.6145 11.1783 28.2867 11.1714 25.9566C11.1509 25.5743 11.2011 25.126 10.9229 24.8165C10.5785 24.5048 10.0814 24.4638 9.63676 24.457C9.47029 24.457 9.21033 24.4616 9.21033 24.2363ZM13.8896 24.9918C13.7049 25.0009 13.689 25.2079 13.6913 25.349C13.6867 26.7757 13.689 28.2025 13.6844 29.6315C13.689 29.7407 13.6753 29.8545 13.7277 29.9569C13.9147 30.0524 14.1336 30.0433 14.3389 30.0593C14.8314 30.0707 15.3377 30.082 15.8166 29.9455C16.2293 29.834 16.5372 29.5018 16.7561 29.1491C17.1757 28.4528 17.2053 27.5767 16.9818 26.8099C16.7903 26.1454 16.3707 25.5174 15.7436 25.1943C15.1735 24.8962 14.5076 24.9235 13.8896 24.9918ZM13.6981 30.9308C13.6616 32.3848 13.6867 33.8411 13.6776 35.2997C13.6935 35.7594 13.6525 36.2418 13.8281 36.681C13.9991 37.0587 14.4392 37.1747 14.8064 37.268C15.6045 37.4228 16.5098 37.4137 17.1916 36.9153C17.8164 36.4739 18.0969 35.6957 18.122 34.9561C18.2132 33.4042 17.2828 31.8706 15.8987 31.1765C15.2123 30.8557 14.4347 30.9308 13.6981 30.9308Z"
            fill={palette.common.black}
          />
          <path
            d="M76.2715 25.7426C76.983 26.1204 77.6739 26.5368 78.3854 26.9145C79.5051 27.5357 80.5791 28.2411 81.733 28.7986C82.3031 29.0831 82.8344 29.4358 83.3749 29.7703C83.726 29.6497 84.0271 29.4221 84.3554 29.2515C85.4181 28.6234 86.5058 28.0363 87.5639 27.4083C88.5308 26.8622 89.4931 26.307 90.4668 25.7768C91.347 26.2933 92.2455 26.7757 93.1143 27.3127C89.8671 29.1855 86.6016 31.0218 83.3361 32.8627C81.2746 31.6817 79.2064 30.5098 77.1426 29.3288C75.9568 28.653 74.7779 27.9658 73.5875 27.3014C74.4632 26.753 75.3822 26.2751 76.2715 25.7426Z"
            fill="#386044"
          />
        </svg>
      ) : (
        <svg
          width="109"
          height="58"
          viewBox="0 0 109 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M83.4521 0H83.5387L83.554 0.0279567C84.5099 0.609964 85.4988 1.14114 86.4674 1.70536C93.9664 5.97003 101.478 10.2169 108.969 14.4917C108.449 14.8399 107.878 15.1144 107.338 15.4372C105.154 16.6622 102.99 17.9253 100.8 19.1376C99.8264 18.6243 98.8833 18.0575 97.9198 17.5212C93.1074 14.7916 88.3001 12.062 83.4903 9.32992C77.7221 12.6136 71.9487 15.8845 66.1805 19.1605C63.4735 17.5873 60.7079 16.1209 58.0417 14.479C66.0683 9.89413 74.1179 5.3499 82.1521 0.777705C82.5803 0.510845 83.0442 0.299899 83.4521 0Z"
            fill="white"
          />
          <path
            d="M3.72911 25.1026C4.56006 24.823 5.38337 24.513 6.21687 24.2283C5.5465 25.466 4.88123 26.7139 4.36125 28.0228C3.83872 29.3291 3.40795 30.6939 3.34932 32.1045C3.30344 33.1262 3.53539 34.219 4.2389 34.9967C4.97299 35.8176 6.07158 36.1963 7.13449 36.3564C7.63663 36.4454 8.14641 36.4301 8.6511 36.514C8.58738 36.5216 8.45993 36.5394 8.40131 36.547C7.58565 36.5826 6.76489 36.6182 5.95688 36.4962C4.85319 36.3412 3.74696 35.904 3.00267 35.0501C2.19211 34.1326 1.89388 32.8822 1.87349 31.6851C1.8429 29.8146 2.3323 27.977 2.97973 26.231C3.10972 25.9184 3.21168 25.5931 3.36716 25.2957C3.44873 25.1763 3.60676 25.156 3.72911 25.1026Z"
            fill="#C5C5C4"
          />
          <path
            d="M83.4724 26.1827C86.7453 24.3223 90.0207 22.4746 93.2909 20.599C93.3317 21.4352 93.2833 22.2739 93.3113 23.1126C93.319 23.4633 93.3215 23.8166 93.3113 24.1698C92.9162 24.3757 92.5441 24.6197 92.1567 24.8383C91.6367 25.1331 91.1142 25.4228 90.6095 25.7379C89.6358 26.2717 88.6723 26.8308 87.7062 27.3772C86.6433 28.0075 85.5575 28.5972 84.4946 29.2275C84.1658 29.4003 83.865 29.629 83.5107 29.7485C83.5132 29.3012 83.4546 28.8538 83.4597 28.4065C83.4903 27.667 83.4215 26.9248 83.4724 26.1827Z"
            fill="#DCDEDC"
          />
          <path
            d="M93.2654 27.2756L93.2986 27.2527C93.3215 30.9786 93.2858 34.707 93.3113 38.4303C91.1167 39.7341 88.8889 40.9744 86.6764 42.2528C85.6084 42.8526 84.5583 43.4905 83.4724 44.0649L83.4775 44.0039C83.4954 43.6862 83.5056 43.371 83.503 43.0533C83.503 41.2895 83.4597 39.5206 83.4724 37.7543C83.4801 36.9867 83.4546 36.2217 83.4572 35.4517C83.4954 34.5825 83.452 33.7158 83.475 32.8441C86.7453 30.9964 90.0156 29.1563 93.2654 27.2756Z"
            fill="#DCDEDC"
          />
          <path
            d="M3.75456 16.4995C4.45297 16.3648 5.2049 16.5224 5.78096 16.9367C6.47937 17.4221 6.91779 18.2557 6.90249 19.1071C6.91014 20.111 6.278 21.0768 5.36294 21.486C4.64159 21.8215 3.7724 21.8062 3.0638 21.4453C2.42912 21.1302 1.93207 20.5456 1.72561 19.8671C1.49621 19.1376 1.60581 18.3091 2.02384 17.6686C2.40618 17.0663 3.04596 16.6343 3.75456 16.4995Z"
            fill="white"
          />
          <path
            d="M76.8529 18.8022C79.0653 17.5466 81.2753 16.2886 83.4903 15.0432C86.7606 16.8884 90.0206 18.7462 93.2884 20.6015C90.0181 22.4772 86.7427 24.3223 83.4699 26.1853C82.9932 25.9692 82.5548 25.682 82.0986 25.4304C79.7128 24.0682 77.3193 22.7212 74.9386 21.3589C74.5231 21.1175 74.1 20.8913 73.6973 20.6295C74.7296 19.989 75.8027 19.4121 76.8529 18.8022Z"
            fill="white"
          />
          <path
            d="M58.0035 14.4968L58.0367 14.4739C60.7029 16.1158 63.4684 17.5822 66.1754 19.1554C66.1831 25.7049 66.1805 32.2595 66.1805 38.8064C71.9334 42.113 77.7246 45.3508 83.4775 48.6624C83.4623 49.5977 83.5081 50.5279 83.4725 51.4632C83.475 53.1406 83.4597 54.8205 83.4954 56.503C83.4954 56.9478 83.5387 57.3951 83.5107 57.8399C83.5209 57.8704 83.5387 57.9288 83.5464 57.9619L83.5387 58H83.5107C81.5811 56.8817 79.6363 55.7838 77.6966 54.6782C73.4755 52.2841 69.2596 49.8824 65.0386 47.4908C62.6936 46.1768 60.3715 44.8197 58.0188 43.521C57.9653 41.1014 57.9984 38.6768 57.9908 36.2573V17.1374C57.9984 16.2555 57.9755 15.3762 58.0035 14.4968Z"
            fill="#EFF0EE"
          />
          <path
            d="M1.70521 24.0707C3.66279 23.3794 5.61782 22.6805 7.57795 21.9918C7.12169 22.7263 6.66288 23.4633 6.23976 24.2156C7.04777 23.9335 7.85323 23.6437 8.66124 23.3591C7.53207 25.1407 6.47681 26.9833 5.69938 28.9479C5.26861 30.0687 4.90667 31.2403 4.86588 32.4501C4.82255 33.4235 5.07744 34.4655 5.80389 35.1594C6.67053 35.9879 7.91441 36.2471 9.07672 36.3107C9.45141 36.3081 9.81591 36.4225 10.1906 36.4098C10.0402 36.425 9.88473 36.425 9.73434 36.4352C9.3724 36.3463 9.013 36.4911 8.65105 36.514C8.14891 36.4301 7.63657 36.4454 7.13698 36.3564C6.07408 36.1988 4.97294 35.8176 4.24139 34.9967C3.53789 34.2165 3.30594 33.1236 3.34927 32.1045C3.41044 30.6914 3.84121 29.3266 4.36119 28.0228C4.87863 26.7139 5.54645 25.466 6.21682 24.2283C5.38332 24.5104 4.56001 24.8205 3.72906 25.1026C3.60671 25.1585 3.44613 25.1788 3.36966 25.2957C3.21417 25.5956 3.10967 25.9209 2.98222 26.2336C2.33224 27.977 1.84285 29.8146 1.87089 31.6876C1.89383 32.8847 2.1895 34.1377 3.00006 35.0526C3.74435 35.9066 4.85314 36.3437 5.95428 36.4987C6.76229 36.6207 7.58305 36.5852 8.3987 36.5496C6.95856 36.7834 5.47253 36.8596 4.03748 36.5597C2.9185 36.3259 1.82756 35.7744 1.11131 34.862C0.305843 33.8454 0.0229113 32.5187 -0.00512695 31.248V30.8769C0.071341 28.5184 0.774846 26.2234 1.70521 24.0707Z"
            fill="white"
          />
          <path
            d="M73.6718 20.6092L73.6998 20.6295C74.1 20.8913 74.5231 21.1175 74.9386 21.3589C77.3193 22.7212 79.7128 24.0682 82.0986 25.4279C82.5548 25.6795 82.9932 25.9667 83.4699 26.1827C83.4215 26.9223 83.4877 27.6644 83.4597 28.4065C83.4546 28.8538 83.5132 29.2986 83.5107 29.7485C82.9677 29.413 82.4376 29.0623 81.8666 28.7776C80.7094 28.221 79.6312 27.517 78.5097 26.8969C77.796 26.5182 77.1026 26.1014 76.3889 25.7252C75.5402 25.2271 74.6812 24.7493 73.8094 24.2893C73.682 24.2512 73.7151 24.0987 73.7024 23.997C73.6743 22.866 73.6769 21.7376 73.6718 20.6092Z"
            fill="#EFF0EE"
          />
          <path
            d="M73.6565 27.2679L73.6998 27.2934C74.8953 27.9542 76.078 28.6404 77.2658 29.3139C79.3355 30.4931 81.4078 31.6648 83.4724 32.8415C83.4495 33.7132 83.4928 34.5799 83.452 35.4491C83.4469 36.2192 83.475 36.9816 83.4673 37.7517C83.4546 39.5181 83.4979 41.287 83.4979 43.0508C83.5005 43.3685 83.4903 43.6836 83.4724 44.0013C83.1309 43.8285 82.8276 43.5921 82.4937 43.4117C81.8615 43.033 81.181 42.7407 80.5488 42.3646C79.5318 41.7775 78.5224 41.1751 77.485 40.6211C76.2258 39.9069 74.9743 39.1775 73.6947 38.4989C73.6514 35.2813 73.682 32.0612 73.6743 28.8411C73.6845 28.3176 73.6743 27.7915 73.6565 27.2679Z"
            fill="#EFF0EE"
          />
          <path
            d="M107.336 15.4346C107.878 15.1119 108.449 14.8374 108.967 14.4892C109.025 14.9721 108.982 15.4601 108.995 15.948V42.6162C108.967 42.911 109.015 43.2262 108.944 43.5083C108.026 44.0547 107.083 44.5655 106.158 45.0993C99.5919 48.8251 93.0258 52.5586 86.4572 56.2896C85.4861 56.8538 84.4971 57.3773 83.5438 57.9644C83.5362 57.9339 83.5183 57.8755 83.5081 57.8424C83.5362 57.3977 83.4903 56.9529 83.4903 56.5081C83.4571 54.8256 83.475 53.1482 83.4699 51.4683C83.5081 50.533 83.4597 49.6003 83.475 48.6675C88.4225 45.849 93.3852 43.0482 98.3327 40.2195C98.7635 39.9883 99.1892 39.757 99.5944 39.4901C99.9589 39.2563 100.354 39.0784 100.736 38.8776C100.831 38.8395 100.805 38.7226 100.823 38.6438C100.879 37.7492 100.836 36.8546 100.8 35.9625C100.792 30.3534 100.81 24.7493 100.792 19.1427C102.987 17.9228 105.151 16.6597 107.336 15.4346Z"
            fill="#DCDEDC"
          />
          <path
            d="M30.1156 22.251C30.266 22.223 30.4342 22.284 30.5209 22.4162C30.6789 22.6373 30.7758 22.8991 30.8905 23.1481C32.3918 26.6351 33.8702 30.1297 35.3766 33.6141C35.7615 34.4731 36.0903 35.4059 36.7964 36.0667C37.1634 36.4225 37.7012 36.3996 38.1804 36.4352C38.3206 36.4403 38.3895 36.6131 38.3155 36.7275C38.1855 36.8266 38.0046 36.8063 37.8542 36.824C36.5134 36.8368 35.1753 36.8164 33.8345 36.7859C33.6331 36.7707 33.4241 36.7859 33.2381 36.7071C33.1004 36.5674 33.2916 36.4352 33.4267 36.4174C33.6229 36.3894 33.6943 36.1582 33.6077 35.9955C33.1106 34.7451 32.6187 33.4896 32.1114 32.2417C32.0681 32.0841 31.8846 32.1222 31.7622 32.1146C30.2609 32.1273 28.757 32.0968 27.2583 32.1273C26.96 32.722 26.7408 33.36 26.4757 33.9699C26.2361 34.5799 25.9124 35.1771 25.8665 35.843C25.8462 36.0235 25.9073 36.2293 26.0807 36.3183C26.3814 36.4835 26.7408 36.3818 27.0645 36.4403C27.2787 36.4606 27.2532 36.8113 27.0442 36.8139C26.2795 36.8545 25.5199 36.7503 24.7552 36.758C23.9447 36.7783 23.1341 36.852 22.3261 36.8164C22.1145 36.8393 22.0508 36.514 22.242 36.4428C22.609 36.3818 22.9964 36.4504 23.3482 36.2903C23.8962 36.0743 24.1919 35.5253 24.4162 35.0145C26.1673 30.9557 27.921 26.8994 29.6696 22.8432C29.7766 22.622 29.8633 22.3374 30.1156 22.251ZM29.2643 26.9807C28.7341 28.3481 28.1631 29.6976 27.6584 31.0726C28.9737 31.0726 30.294 31.1031 31.6067 31.0573C30.9695 29.3774 30.3144 27.7025 29.6721 26.0277C29.4911 26.3225 29.4045 26.6631 29.2643 26.9807Z"
            fill="white"
          />
          <path
            d="M41.2469 22.9753C41.2571 22.7542 41.2876 22.4467 41.5323 22.3577C41.8051 22.3425 41.9988 22.5712 42.1823 22.7339C44.0481 24.6121 45.9063 26.4903 47.8002 28.3379C48.8758 29.3825 49.9336 30.4474 50.9889 31.5148C51.5496 32.0587 52.0696 32.6458 52.6559 33.1643C52.6227 30.3432 52.5284 27.5247 52.4673 24.7061C52.4367 24.2436 52.4596 23.6971 52.1028 23.3439C51.6873 22.9931 51.1036 23.1126 50.6065 23.0389C50.4103 22.988 50.4664 22.6856 50.6728 22.7059C51.8045 22.6373 52.9414 22.7695 54.0756 22.7085C54.5829 22.7059 55.1003 22.6551 55.605 22.7059C55.7375 22.7009 55.8421 22.8533 55.7503 22.9677C55.6177 23.0948 55.4189 23.049 55.2558 23.0694C54.9091 23.0821 54.5013 23.1812 54.3331 23.5141C54.1623 23.8649 54.1649 24.2588 54.1496 24.64C54.0782 28.4396 54.0068 32.2417 53.938 36.0438C53.9023 36.3717 54.0272 36.8597 53.6678 37.035C53.3645 37.0604 53.0892 36.8927 52.8751 36.6894C50.8436 34.7959 48.8401 32.8771 46.8367 30.9506C45.7381 29.8857 44.6701 28.7852 43.6148 27.6721C43.2656 27.3188 42.9393 26.935 42.5596 26.6097C42.6233 29.2249 42.7023 31.8376 42.7813 34.4503C42.8272 35.0145 42.8195 35.6499 43.184 36.1175C43.5638 36.4632 44.1272 36.3742 44.5962 36.4327C44.7363 36.4454 44.8281 36.5852 44.7593 36.7148C44.6573 36.8088 44.5069 36.8114 44.3744 36.8164C43.5434 36.8266 42.7023 36.7758 41.8713 36.7504C41.1118 36.7707 40.3522 36.8342 39.5926 36.8139C39.4855 36.8012 39.3148 36.8012 39.2867 36.664C39.2459 36.5165 39.4014 36.4327 39.5263 36.4225C39.8857 36.3895 40.263 36.4174 40.6045 36.2802C40.8288 36.176 40.9231 35.9244 40.969 35.6956C41.0582 35.2153 41.0557 34.7222 41.071 34.2317C41.1271 30.483 41.1857 26.7292 41.2469 22.9753Z"
            fill="white"
          />
          <path
            d="M9.20929 22.8381C9.28576 22.678 9.49222 22.7009 9.64006 22.6856C10.5398 22.6856 11.4396 22.7186 12.3394 22.7288C13.2876 22.7237 14.2358 22.6958 15.1814 22.6856C15.992 22.6907 16.823 22.772 17.58 23.077C18.1815 23.3184 18.7321 23.7353 19.0354 24.3147C19.4203 25.0187 19.4025 25.8905 19.0991 26.6199C18.7245 27.5272 17.9725 28.2236 17.1671 28.7573C18.2325 29.1131 19.247 29.7104 19.9505 30.6024C20.473 31.2581 20.7611 32.0968 20.7203 32.9355C20.6948 34.0843 20.0856 35.1873 19.1552 35.8532C18.077 36.6385 16.7057 36.8927 15.393 36.8622C14.3275 36.8292 13.2621 36.7478 12.2017 36.758C11.5314 36.7732 10.861 36.7936 10.1932 36.8215C9.99946 36.819 9.79045 36.8469 9.60947 36.7631C9.45144 36.6589 9.55594 36.4301 9.73947 36.4352C9.88985 36.4251 10.0428 36.4251 10.1957 36.4098C10.443 36.3793 10.7361 36.3412 10.8865 36.1124C11.0649 35.8253 11.0828 35.472 11.1184 35.1441C11.2026 33.9471 11.1771 32.7526 11.1796 31.5555C11.1745 29.2249 11.1873 26.8944 11.1745 24.5638C11.1516 24.1826 11.2 23.7327 10.9222 23.4277C10.5755 23.1126 10.081 23.0719 9.63496 23.0694C9.46928 23.0592 9.21184 23.0668 9.20929 22.8381ZM13.8968 23.5878C13.7133 23.598 13.6954 23.8064 13.7005 23.9437C13.698 25.372 13.7031 26.8003 13.698 28.2312C13.7031 28.3405 13.6878 28.4549 13.7438 28.5591C13.9299 28.6531 14.1517 28.6429 14.3556 28.6607C14.8475 28.6709 15.3573 28.6836 15.8365 28.5463C16.2494 28.432 16.5553 28.1016 16.7745 27.7483C17.1951 27.0494 17.2231 26.1726 16.9988 25.405C16.8077 24.7392 16.3845 24.1089 15.7575 23.7886C15.184 23.4938 14.5187 23.5218 13.8968 23.5878ZM13.7133 29.535C13.6827 30.9913 13.7056 32.4501 13.7005 33.9064C13.7209 34.3664 13.675 34.8519 13.8534 35.289C14.0242 35.6677 14.4652 35.7846 14.8322 35.8761C15.6326 36.0286 16.54 36.0184 17.2231 35.5203C17.8476 35.0806 18.1255 34.2978 18.1509 33.5582C18.2427 32.0054 17.3072 30.4703 15.9206 29.779C15.2273 29.4562 14.4524 29.5325 13.7133 29.535Z"
            fill="white"
          />
          <path
            d="M76.3889 25.7253C77.1026 26.1014 77.7959 26.5182 78.5097 26.8969C79.6312 27.5196 80.7094 28.221 81.864 28.7776C82.435 29.0623 82.9677 29.4155 83.5081 29.751C83.8599 29.629 84.1632 29.4003 84.492 29.23C85.5549 28.6022 86.6407 28.0126 87.7036 27.3823C88.6697 26.8359 89.6332 26.2768 90.6069 25.743C91.4939 26.259 92.3937 26.7419 93.2654 27.2781C90.013 29.1588 86.7452 30.9989 83.475 32.8466C81.4078 31.6673 79.333 30.4957 77.2683 29.319C76.078 28.6455 74.8978 27.9567 73.7024 27.2959C74.5792 26.7393 75.4994 26.2615 76.3889 25.7253Z"
            fill="#C5C5C4"
          />
        </svg>
      )}
    </Box>
  );
}
