import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Beneficios',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/agentes#beneficios'
  },
  {
    title: '¿Cómo funciona?',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/agentes#como-funciona'
  }
];

export default menuConfig;
