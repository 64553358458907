import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

const SubNav = styled(motion.div)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none'
  },
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    width: '100%',
    height: '4em',
    boxShadow: '-1px -1px 4px 1px rgba(0,0,0,0.25)',
    backgroundColor: theme.palette.common.white
  }
}));

interface FloatNavbarProps {
  isOffset: boolean;
  buttons: Array<() => JSX.Element>;
}
export const FloatNavbar = ({ isOffset, buttons }: FloatNavbarProps) => {
  if (!isMobile) {
    return (
      <>
        {buttons.map((Element, index) => (
          <Element key={index} />
        ))}
      </>
    );
  }

  if (isMobile) {
    if (isOffset) {
      return (
        <SubNav>
          <Stack direction={'row'}>
            {buttons.map((Element, index) => (
              <Element key={index} />
            ))}
          </Stack>
        </SubNav>
      );
    }
    return <></>;
  }
  return <></>;
};
