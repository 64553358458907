import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const Link = styled('a')(({ theme }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.palette.primary.main,
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  zIndex: 100,
  [theme.breakpoints.down('md')]: {
    bottom: '80px',
    right: '20px'
  }
}));

const Point = styled('div')(({ theme }) => ({
  width: '22px',
  height: '20px',
  position: 'relative',
  top: '-7px',
  left: '-15px',
  transform: 'rotate(-60deg)',
  background: '#fff',
  border: `2px solid ${theme.palette.primary.main}`,
  borderLeft: 'none',
  borderTop: 'none'
}));

const PonchoIMG = styled('img')(({ theme }) => ({
  width: '100px',
  borderRadius: '50%',
  boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.3)',
  [theme.breakpoints.down('md')]: {
    width: '50px'
  }
}));
const Text = styled(Typography)(({ theme }) => ({
  background: '#fff',
  padding: '20px 50px',
  borderRadius: '50px',
  border: `2px solid ${theme.palette.primary.main}`
}));

export const Poncho = () => {
  return (
    <Link href="https://wa.me/5215561983481" target={'_blank'}>
      <PonchoIMG src={'/static/poncho/Icon_poncho.svg'} alt="Poncho" />
    </Link>
  );
};
